import React from 'react';

const videoDb = [
    {
        title : "Jameson Flotation Cell Training Module",
        description : <p>Founded in 1983, Performance Associates International (PAI) is the worldwide leader in mine and plant industrial training. Our custom training modules and on-site training have improved start-up success and operations for clients on nearly every continent.<br /><br />We invite you to experience one of our e-learning training modules. Here you will see a video demonstration of a sample principle of operation from an e-learning module.<br /><br />In this sample, you will learn about Jameson Cell flotation technology. We describe in detail the key components of the Jameson Cell and the operating principles. At the end of the module, a test will help assess what you learned. In a full training program, test results can be recorded and tracked in an LMS. A principle of operation like this would be only one of many included with a full plant operations training program.</p>,
        tn: "/thumb/tn_flot_cell.webp",
        video: "ngVIo4zLj6U"
    },{
        title : "PAI Jaw Crusher Training",
        description : <p>Three dimensional animations integrated into a process plant computer-based training (CBT) program can greatly enhance trainee understanding of process equipment. This introductory maintenance manual and animation provides a narrative description of a jaw crusher’s location in the process, its function, components, and maintenance requirements. The animation introduces maintenance personnel to the jaw crusher in a dynamic visual context.<br /><br />The narrated animation replaced the standard text and graphics in the description section of the equipment maintenance (CBT) module. The descriptive text was adapted to a narrative form. Multimedia specialists used engineering drawings and photographs to create models of the equipment and animate the modeled images to illustrate the key concepts and components in concert with the narration.</p>,
        tn: "/thumb/tn_jaw_crusher.webp",
        video: "NQdz5AyN-a4"
    },{
        title : "Swakop Review of PAI Services",
        description : <p>A positive testimonial of Performance Associates International's (PAI) training from Swakop.</p>,
        tn: "/thumb/tn_vid_pai_testimonial_swakop_sm.webp",
        video: "ko3BhKHyrXU"
    },{
        title : "Torex Gold Review of PAI Services",
        description : <p>In 2015, Performance Associates International (PAI) developed an extensive operations and maintenance training program for Torex Gold’s El Limón-Guajes project in the state of Guerrero, Mexico. The venture was a “massive success,” according to Nelson Bodnarchuk, Director of Operational Systems. This video tells the story of that success, including generous feedback from the executive officers at Torex recognizing our role in the partnership.</p>,
        tn: "/thumb/tn_vid_pai_testimonial_torex_sm.webp",
        video: "_MLKPmzBnS8"
    },{
        title : "Crítica positiva de los servicios de PAI por Torex Gold",
        description : <p>En el 2015, Performance Associates International (PAI) desarrolló un programa de capacitación por computadora comprensivo, el cual abarca operaciones y mantenimiento, para su planta de oro El Limón-Guajes en el estado de Guerrero, México. El emprendimiento fue un “éxito masivo,” según Nelson Bodnarchuk, Director, Sistemas Operacionales. Este video cuenta la historia de ese éxito, incluyendo comentarios generosos de los oficiales ejecutivos de Torex, quienes reconocen nuestro papel en la colaboración.</p>,
        tn: "/thumb/tn_vid_es_pai_testimonial_torex_sm.webp",
        video: "iPrVlaDLYIA"
    },{
        title : "PAI Commercial Ad",
        description : <p>For more than 30 years, Performance Associates has been a pioneer in mine and plant industrial training, developing and delivering first-class computer-based training (CBT) programs. Performance Associates’ training programs are customized and designed for both mine and plant operations. We provide mine and plant industrial training to improve performance in current operations and ensure success during start-up of new operations.</p>,
        tn: "/thumb/tn_vid_pai_commercial_ad_sm.webp",
        video: "7cEt3NBShH8"
    },{
        title : "PAI Tour",
        description : <p>Founded in 1983, Performance Associates International (PAI) is the worldwide leader in mine and plant industrial training. Our custom interactive modules have improved start-up success and operations for hundreds of clients on nearly every continent.</p>,
        tn: "/thumb/tn_vid_pai_tour_sm.webp",
        video: "_dFLDkXqOd0"
    },{
        title : "Basic Training Services Tour",
        description : <p>This is an overview of our basic training services, focusing on Introduction to Plant Unit Operations, an off-the-shelf, in-depth computer-based module complete with narration, graphics, videos, simulations, 3D animations, and assessments, customizable to your plant needs.</p>,
        tn: "/thumb/tn_vid_pai_basic_training_services_tour_sm.webp",
        video: "xeFk8tysAY0"
    },{
        title : "Intermediate Training Services Tour",
        description : <p>This is an overview of our intermediate training services, focusing on Site-Specific Plant Operations, a fully customized computer-based module complete with graphics, animated flowsheets, animations, and assessments, specific to your plant design down to every last detail.</p>,
        tn: "/thumb/tn_vid_pai_intermediate_training_services_tour_sm.webp",
        video: "QWWPoVMObpg"
    },{
        title : "PAI Operations Training Demo",
        description : <p>PAI's computer-based training (CBT) programs and associated 3D media products provide an effective, interactive method of training plant operators. This video provides examples of typical PAI CBT programs.</p>,
        tn: "/thumb/tn_vid_pai_operations_training_demo_sm.webp",
        video: "X92-YCwtayY"
    },{
        title : "PAI Maintenance Training Demo",
        description : <p>Maintenance planning, preventive and breakdown service, cost control, mechanical, electrical, lubrication, sampling—we bring it all together. Exploded graphics and detailed procedures help ensure that your crews know how to keep equipment running and get it back online when down.</p>,
        tn: "/thumb/tn_vid_pai_maintenance_demo_sm.webp",
        video: "f7n3ln3Mmgs"
    },{
        title : "PAI Mobile Equipment Training Demo",
        description : <p>The largest common denominator of accidents is mobile equipment, and some of the largest costs of an operation are associated with mobile equipment. Training programs developed by PAI will help increase awareness, decrease accidents, and cut unwanted costs from your mobile equipment operations and maintenance.</p>,
        tn: "/thumb/tn_vid_pai_mob_equip_demo_sm.webp",
        video: "3SLYxCc5nZc"
    },{
        title : "PAI 3D Portfolio",
        description : <p>State-of-the-art graphics, voice-over audio narratives, and technically engineered variables provide life-like scenarios. When critical areas require skillful training at its best, nothing matches the effectiveness of a premier first-class simulator model.</p>,
        tn: "/thumb/tn_vid_pai_sim_anim_demo_sm.webp",
        video: "PvKzD-Z1g6E"
    },{
        title : "eLearning Grinding Circuit Example",
        description : <p>This video is an example of a user experience of our eLearning module for plant operators, specifically for a gold mill grinding circuit. The overview of the module includes: <ul><li>Language toggle controls</li><li>Language toggle controls</li><li>Interactive process flowsheets</li><li>Process descriptions</li><li>Principles of operation</li><li>Workbook feature</li><li>Equipment (SAG mill) 3D animation</li><li>Process variables</li><li>Control loops</li><li>Interlocks</li><li>Alarms</li><li>Interactive plot plan</li></ul></p>,
        tn: "/thumb/tn_vid_pai_elearning_grinding_circuit_sm.webp",
        video: "D2dvTJP-ahc"
    },{
        title : "Capacitación PAI—Triturador de cono",
        description : <p>Este video es un ejemplo de una experiencia de usuario de nuestro módulo de aprendizaje en línea para operadores de planta, específicamente para un circuito de molienda en un molino de oro. La perspectiva general del módulo incluye:<ul><li>Botones para cambiar de idioma</li><li>Diagramas de flujo interactivos</li><li>Descripciones del proceso</li><li>Principios de operación</li><li>Ejercicios</li><li>Animaciones 3D</li><li>Variables del proceso</li><li>Lazos de control</li><li>Enclavamientos (interconexiones)</li><li>Alarmas</li><li>Planos de terreno interactivos</li></ul></p>,
        tn: "/thumb/tn_vid_es_pai_elearning_grinding_circuit_sm.webp",
        video: "2LqsWJ1txAs"
    },{
        title : "PAI WebGL 3DLOs (3D Learning Objects)",
        description : <p>Our 3DLOs are WebGL tools that offer a unique way to learn about a component or piece of equipment, allowing users to rotate, swipe, zoom, and explode and implode objects for maximum comprehension. They are customizable, cross-platform, responsive, scalable, and modular.</p>,
        tn: "/thumb/tn_vid_pai_webgl_sm.webp",
        video: "LSVbTrCHq6Y"
    },{
        title : "Capacitación PAI—WebGL",
        description : <p>Nuestros objetos de aprendizaje 3D son herramientas WebGL que ofrecen una manera única de aprender acerca de un componente o equipo, ya que les permite a los usuarios rotar, deslizar, ampliar, explosionar e implosionar objetos para lograr la comprensión máxima. Los objetos 3D son personalizables, compatibles con múltiples plataformas, responsivos, escalables y modulares.</p>,
        tn: "/thumb/tn_vid_es_pai_webgl_sm.webp",
        video: "mmsNsX54hk4"
    },{
        title : "PAI Primary Crushing Training",
        description : <p>In this training module, we'll cover the characteristics of a gyratory crusher and its principle of operation, and we'll provide detailed descriptions of its major components and the role that each component plays in its operation.<br /><br />We’ll also cover the apron feeder, which conveys the crushed ore from the crusher to a downstream discharge conveyor belt, as well as its  principle of operation and detailed descriptions of its components.</p>,
        tn: "/thumb/tn_vid_pai_primary_crushing_sm.webp",
        video: "bvmPaGjYP6o"
    },{
        title : "PAI Cone Crusher Training",
        description : <p>This module explains the cone crusher’s principle of operation and provides detailed descriptions of the major components and the role that each component plays in its operation. You'll learn how the drive system operates, the details of the dust sealing system, power-draw control, the method of adjusting the closed- and open-side setting, the hydraulic and tramp-material release systems, and details of the lubrication system.</p>,
        tn: "/thumb/tn_vid_pai_cone_crusher_sm.webp",
        video: "CTsBq9nC78M"
    },{
        title : "Capacitación PAI – Triturador de cono",
        description : <p>Este módulo explica el principio de operación del triturador de cono y describe en detalle los componentes principales y la contribución de cada uno de ellos a la operación del triturador. Se aprenderá cómo funciona el sistema de accionamiento y se impartirá información sobre el sistema de sellos contra polvo, el control de consumo de energía, el método que se emplea para el ajuste del lado cerrado y del lado abierto, el sistema hidráulico, el sistema de liberación de chatarra y el sistema de lubricación.</p>,
        tn: "/thumb/tn_vid_es_pai_cone_crusher_sm.webp",
        video: "_WYJCPNMGmw"
    },{
        title : "PAI Vertical Roller Mill Training",
        description : <p>In this training module, we'll cover the operation of a vertical roller mill. This mill is commonly used in the cement industry in order to grind raw feed.<br /><br />We'll describe the general arrangement of the mill, the principle of operation, and provide detailed descriptions of the major components of the mill and the role that each component plays in the overall operation.<br /><br />You'll learn how the mill feed is fed onto a rotating table, where pressure generated between the rollers and table crushes and grinds the raw feed.<br /><br />We’ll also look at the mechanism by which hot gases, directed vertically from the bottom of the mill, entrain and dry the ground feed as the feed is swept up into the rotating classifier, how coarser oversize material is directed back down onto the table for further grinding, and how sufficiently ground, finer material continues up through the classifier and out of the mill.</p>,
        tn: "/thumb/tn_vid_pai_vertical_roller_mill_sm.webp",
        video: "ItjpblWKx80"
    },{
        title : "Capacitación PAI—Molino de rodillos vertical",
        description : <p>En este módulo de capacitación, examinaremos la operación del molino de rodillos vertical. Este molino se usa comúnmente en la industria del cemento para moler la alimentación de materia prima.<br /><br />Se describirá el manejo general y el principio de operación del molino, y se proporcionará detalles sobre los componentes principales y la contribución de cada uno de ellos a la operación del molino.<br /><br />Se aprenderá cómo la materia prima se alimenta a una mesa rotatoria dentro del molino, donde la presión generada entre los rodillos y la mesa tritura y muele la materia prima.<br /><br />También veremos cómo los gases calientes, que se dirigen verticalmente hacia arriba desde el fondo del molino, secan el material molido a medida que se arrastra hacia el clasificador rotatorio; cómo el material de sobretamaño más grueso se dirige nuevamente hacia abajo y regresa a la mesa rotatoria para molienda adicional; y cómo el material más fino, suficientemente molido, continúa hacia arriba a través del clasificador hasta salir del molino.</p>,
        tn: "/thumb/tn_vid_es_pai_vertical_roller_mill_sm.webp",
        video: "xdrRDhEH3zU"
    },{
        title : "PAI SAG Mill Training",
        description : <p>In this training module, we'll cover the workings of a semi-autogenous grinding mill, or SAG mill. We'll also describe the general arrangement of a SAG mill, its principle of operation, and provide detailed descriptions of its major components and the role that each component plays in its operation.</p>,
        tn: "/thumb/tn_vid_pai_sag_mill_sm.webp",
        video: "QE9ur8b8FJs"
    },{
        title : "PAI Autoclave Circuit Training",
        description : <p>In this training module, we'll cover the workings of an autoclave circuit that's designed to oxidize finely ground refractory gold ore so that it's suitable for conventional cyanide leaching.<br /><br />We'll describe the general arrangement of the equipment in the circuit and give detailed descriptions of the major components and the role that each component plays in the overall process.<br /><br />Lastly, we'll go over some critical safety hazards associated with the circuit, including steam and oxygen blowback as well as the potential for fire.</p>,
        tn: "/thumb/tn_vid_pai_autoclave_circuit_sm.webp",
        video: "OwbDklKTVw8"
    },{
        title : "PAI Carbon Adsorption Training",
        description : <p>In this training module, we'll cover how activated carbon is used to concentrate gold and silver from pregnant leach solution. The concentrating process is based on the concept of adsorption, in which complex gold, silver, and (if present) mercury molecules are adsorbed—or adhered—onto small, millimeter sized particles of activated carbon.<br /><br />The adsorption process is carried out at atmospheric temperature and pressure. During the process, the metals migrate from a cyanide leach solution to the surfaces of carbon pores. In addition to the animation, this module includes an animated process flowsheet, process variable target ranges and control methods, as well as alarm response procedures.</p>,
        tn: "/thumb/tn_vid_pai_carbon_adsorption_sm.webp",
        video: "rdzG_MuA-Ek"
    },{
        title : "PID Simulator",
        description : <p>Performance Associates International (PAI) PID Simulator</p>,
        tn: "/thumb/tn_vid_pai_pid_simulator_sm.webp",
        video: "4YdJQsiUUSY"
    },{
        title : "eLearning Oriented Strand Board Example",
        description : <p>Performance Associates International (PAI) Computer Based Training (CBT) Example for Oriented Strand Board (OSB)</p>,
        tn: "/thumb/tn_vid_pai_elearning_osb_sm.webp",
        video: "6R8xcMfegQY"
    }
];

export default videoDb;