import React from 'react';

const whitePagesList = [
    {
        image: 'article_thumbnail_images/productivity_profile_article.webp',
        name: <p>How does your plant measure up?<br />Develop a productivity profile to find out</p>,
        file: '/wp/Process_Plant_Productivity_Profile.pdf',
        description: "How can the overall performance of your plant be determined? Most plants calculate availability, or the percent of the time the plant or a particular circuit is operating, as well as overall production and recovery. But, is this information enough? Does it provide sufficient fine-grained information on where improvements can be made?"
    },
    {
        image: 'article_thumbnail_images/operations_readiness.webp',
        name: <p>So You Are Investing in a Mining Project—<br />What Usually Goes Wrong?</p>,
        file: '/wp/Gold_Mine_SU_Financial_Impact_Update.pdf',
        description: "The geology report, feasibility study, and mine plan look great. Why do so many mining project start-ups fail to perform? Most mining companies put a great deal of emphasis on the nature of the reserve, the mine plan, the extraction process to be used, and the process plant design—obviously all very important. Even when large, highly regarded engineering, procurement, and construction firms develop the project, operating results are very often disappointing. In this paper, we explore the role of Operations Readiness on the financial impact of mining project start-ups. A relatively small investment in operations readiness produces a huge financial return. Many investment firms, as well as mining company senior executives, fail to consider this important factor in operating success."
    },
    {
        image: 'article_thumbnail_images/process_control_training_article.webp',
        name: <p>Process Control Training—<br />Simulators are Only Half The Story.</p>,
        file: '/wp/Process_Simulators_are_only_Half_the_Story_Paper.pdf',
        description: "With reference to greenfield plant projects, using process simulators similar to the designed plant Human-Machine Interface (HMI) or Distributed Control System (DCS) has become common practice. These simulators represent a “virtual plant” based on process modeling of the circuit chemistry and thermodynamics, and on the physical nature of the plant, including equipment, valves, piping, etc. The virtual plant allows trainees to troubleshoot problems, optimize process variables, react to alarms, etc., all based on the process simulation model."
    },
    {
        image: 'article_thumbnail_images/training_pays_article.webp',
        name: <p>Training Pays—<br />Actual vs. Projected Start-Up Results</p>,
        file: '/wp/PAI_Start-Up_Results.pdf',
        description: "Developing a profitable mining venture is no small matter. It requires enormous initial investments in research, construction, and equipment. But a mineral processing plant does not run itself. Even with the best equipment and good ore quality, optimal results are dependent upon keeping the process running smoothly, avoiding process upsets, maintaining process variables in the desired range, and minimizing breakdowns and downtime. A highly trained workforce is an essential element in a successful, and profitable, start-up."
    },
    {
        image: 'article_thumbnail_images/spare_parts_planning_article.webp',
        name: <p>Spare Parts Planning—<br />Why You Need a Spare Parts Inventory Model</p>,
        file: '/wp/Parts_Inventory_Model_Paper.pdf',
        description: "Ensure that your plant has a good inventory of spare parts on hand by learning how to collect the data necessary to build your own spare parts inventory model."
    },
    {
        image: 'article_thumbnail_images/training_myths_article.webp',
        name: <p>Don't Think You Need Expert Training? Common Myths and Our Responses</p>,
        file: '/wp/PAI_training_myths.pdf',
        description: "In this interactive PDF, we talk about common training myths and our responses."
    },
    {
        image: 'article_thumbnail_images/mining-process_plant_maintenance_safety_training_article.webp',
        name: <p>Mining, Process Plant, Maintenance, and Safety Training</p>,
        file: '/wp/MMI_PAI_Tour_Final.pdf',
        description: "Performance Associates International, Inc. (PAI) is the world leader in online and on-site training for the mining industry. We provide organizations with a single source for assessments, skills training, continuing education, safety training and compliance mandates, and more. Our first-class content guides your staff to gain the critical knowledge they need to work safely and efficiently today."
    },
    {
        image: 'article_thumbnail_images/metallurgical_plant-commissioning_article.webp',
        name: <p>Metallurgical Plant Commissioning</p>,
        file: '/wp/Metallurgical_Plant_Commissioning.pdf',
        description: "During the final stages of metallurgical plant construction there is tremendous pressure to commence production. More often than not, there will have been budget overruns and schedule delays. Corporate management and investors are naturally anxious to generate cash flow. This pressure may evolve into a fast-track culture regarding the commissioning and start-up."
    },
    {
        image: 'article_thumbnail_images/safety_article.webp',
        name: <p>Process Plant Safety</p>,
        file: '/wp/Process_Plant_Safety.pdf',
        description: "In many mine and plant operations, safety is looked upon as an extra layer of special attention placed above the operations management layer. But safety awareness must be built into the detailed operations and maintenance training on the equipment as well as on the process itself."
    },
    {
        image: 'article_thumbnail_images/process_variables_article.webp',
        name: <p>Are Your Process Variables Under Control?</p>,
        file: '/wp/Are_Your_Process_Variables_Under_Control.pdf',
        description: "What would it mean to your process if the variation in your key process variables could be cut in half?"
    },
    {
        image: '/article_thumbnail_images/preparation_of_effective_cbt_programs_article.webp',
        name: <p>Preparation of Effective Computer-Based Operator Training (CBT) Programs for Metallurgical Plants.</p>,
        file: '/wp/cbt_process_trng_2015.pdf',
        description: "Effective plant-specific computer-based training (CBT) used in a formal training program can make the difference between a successful start-up and a failure. Or, in the case of an existing plant, it can make a huge difference in the effectiveness of operating results—particularly if turnover of experienced personnel is a factor. Once the plant process design and control strategies have been established, equipment has been ordered, and the plant is either operating or under construction, the only major variable affecting success is the capability of plant operating personnel. It is essential that the myriad details concerning plant operation are documented in a comprehensive CBT system suitable for training the non-technical personnel who will operate the plant.",
        isTall: true
    }
]
    // {
    //     image: 'article_thumbnail_images/training_myths_article.png',
    //     name: 'Maintenance Cost Control—Is Your System Active or Passive?',
    //     file: '/wp/maint_cost_control.pdf'
    // },
    // {
    //     image: 'article_thumbnail_images/training_myths_article.png',
    //     name: 'If You Are Planning on Developing a New Mining Project, You Need an Operations Readiness "Road Map".',
    //     file: '/wp/Operations_Readiness_Planning_Stdy.pdf'
    // },
    // {
    // {
    //     image: 'article_thumbnail_images/training_myths_article.png',
    //     name: 'How Does Your Plant Measure Up? Develop a Productivity Profile to Find Out.',
    //     file: '/wp/Process_Plant_Productivity_Profile.pdf'
    // },
    // {
    //     image: 'article_thumbnail_images/training_myths_article.png',
    //     name: 'Total Quality Management—The Time Has Come For Metallurgical Plants.',
    //     file: '/wp/Total_Quality_Management.pdf'
    // }


export default whitePagesList;