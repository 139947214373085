import React, { useRef, useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import "./ContactUs.css";
// import axios from 'axios';
import { ErrorMessage } from "@hookform/error-message";

function ContactUs({ id, shadow }) {
  // eslint-disable-next-line
  const mailTo = "performance@perfnet.com";
  const captureRef = useRef();
  const [formCapture, setFormCapture] = useState("");
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [postMessage, setPostMessage] = useState();
  const { register, handleSubmit, errors } = useForm();

  function changeCapture(e) {
    setFormCapture(e.target.value);
  }

  function closeModal() {
    setSuccess(false);
  }

  // For testing modal styling after form submission
  // function testSubmit(){
  //     setSubmitted(true);
  //     setSuccess(true);
  //     setPostMessage("Thank you for your interest!");
  // }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      // height: '50%',
      // width: '80%',
      height: "150px",
      width: "300px",
      padding: 0,
      boxSizing: "border-box",
      overflow: "hidden",
    },
    overlay: {
      zIndex: 50,
      backgroundColor: "rgba(51,51,51,.4)",
    },
  };

  Modal.setAppElement("#root");

  function onSubmit(e) {
    const templateParams = {
      fname: e.fname,
      lname: e.lname,
      replyto: e.replyto,
      message: e.message,
    };

    if (!captureRef.current.value.length > 0 || formCapture === "") {
      try {
        emailjs
          .send(
            "service_xs4o83g",
            "template_wmpcdkk",
            templateParams,
            "user_2M5Gyd8Jx1VS5odfVvHzO"
          )
          .then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );

        /**
         * no longer working
         * switched to emailjs
         */
        // await axios.post(`https://mailthis.to/${mailTo}`, {
        //     _subject: "A new contact submission from the PAI Website!",
        //     name: `${e.fname} ${e.lname}`,
        //     _replyto: e._replyto,
        //     message: e.message,
        //     _honeypot: formCapture
        // });

        setSubmitted(true);
        setSuccess(true);
        setPostMessage("Thank you for your interest!");
      } catch (error) {
        setPostMessage("There was an error with your request.");
        alert(postMessage);
      }
    } else {
      setSubmitted(true);
      console.log("didn't work");
    }
  }

  return (
    <div className={`contact-form-wrapper ${shadow && "drop-shadow"}`}>
      {submitted ? (
        <React.Fragment>
          <Modal
            isOpen={success}
            onRequestClose={closeModal}
            style={customStyles}
            shouldCloseOnOverlayClick={true}
          >
            <div className="form-modal-close">
              <button onClick={closeModal}>&times;</button>
            </div>
            <div className="form-submitted">
              <div>
                {postMessage}
                <br />
                Your message has been sent.
              </div>
            </div>
            {/* <iframe className="form-submitted-modal-iframe" src="https://mailthis.to/confirm" title="Email Confirmation" scrolling="no"></iframe> */}
          </Modal>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="contact-form-header">
            <div>
              <h2>Contact Us</h2>
              <p>
                Fill in the form and we will contact you to discuss your
                training needs.
              </p>
            </div>
            <div>
              <i className="fas fa-pencil-alt fa-3x"></i>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <form onSubmit={onSubmit}> */}
            <input
              className={errors.fname && "form-error"}
              type="text"
              name="fname"
              placeholder="First Name"
              ref={register({
                required: "Please input your first name.",
                maxLength: 80,
              })}
            />
            <ErrorMessage
              className="form-error-note"
              errors={errors}
              name="fname"
              as={"div"}
            />
            <input
              className={errors.lname && "form-error"}
              type="text"
              name="lname"
              placeholder="Last Name"
              ref={register({
                required: "Please input your last name.",
                maxLength: 100,
              })}
            />
            <ErrorMessage
              className="form-error-note"
              errors={errors}
              name="lname"
              as={"div"}
            />
            <input
              className={errors.replyto && "form-error"}
              type="email"
              name="replyto"
              placeholder="Email"
              ref={register({
                required: "Please provide your email address.",
                pattern: /^\S+@\S+$/i,
              })}
            />
            <ErrorMessage
              className="form-error-note"
              errors={errors}
              name="replyto"
              as={"div"}
            />
            <textarea
              className={errors.message && "form-error"}
              name="message"
              placeholder="Tell us about your training needs:"
              rows="5"
              ref={register({
                required: "Please let us know how we can help you!",
              })}
            ></textarea>
            <ErrorMessage
              className="form-error-note"
              errors={errors}
              name="message"
              as={"div"}
            />
            <input
              type="hidden"
              name="_honeypot"
              value={formCapture}
              ref={captureRef}
              onChange={(e) => changeCapture(e)}
            />
            <button type="submit">Submit</button>
          </form>
        </React.Fragment>
      )}
    </div>
  );
}

export default ContactUs;
