import React, { useState, useRef } from 'react';
import CarouselItem from '../../../utils/carousel/CarouselItem';
import carouselList from '../../../utils/carousel/carousel-list';
import './Carousel.css';
import Expand from '../../../common/expand/Expand';
import { Element, Link } from 'react-scroll';

function Carousel() {
    const [expanded, setExpanded] = useState(false);
    const [emitter, setEmitter] = useState(true);
    const changeEmitter = () => setEmitter(!emitter);
    let itemList = carouselList.map((carouselItem, index) => <CarouselItem key={"caroI" + index.toString()} job={carouselItem} emitter={changeEmitter} />);
    const itemsRef = useRef();
    
    return (
        <Element name="projects" className="element">
            <div className="carousel">
                <div className="carousel-header row mt-3 mt-md-5 m-0 p-0">
                    <h3>Projects</h3>
                </div>
                <div className="carousel-content row m-0 p-0">
                    <div className="carousel-items row m-0" ref={itemsRef}>
                        {itemList.slice(0,4)}
                        <Expand className="carousel-items-expanded" isOpen={expanded} size={itemsRef}>
                            {itemList.slice(4)}
                        </Expand>
                    </div>
                    <Link to="projects" offset={-60} type="button" className="carousel-expand" onClick={() => setExpanded(!expanded)}>
                        {expanded ? 
                            <i className="fas fa-chevron-up"></i> :
                            <i className="fas fa-chevron-down"></i>
                        }
                    </Link>
                </div>
            </div>
        </Element>
    )
}

export default Carousel;