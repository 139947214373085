import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import './Location.css';
// import WorldMap from '../WorldMap/WorldMap';

function Location() {
    const [locationVis, setLocationVis] = useState(false);
    const locationCheckVisibility = (isVisible) => {
        !locationVis && setLocationVis(isVisible);
    }
    return (
        <VisibilitySensor onChange={locationCheckVisibility} partialVisibility={'top'} minTopValue={100}>
            <div className="location-wrapper">
                <div className="location-content row m-0">
                    <div className="location-content-group col-12 col-lg-6">
                        <div className={`location-wrapper-text-content ${!locationVis && "hidden-left"}`}>
                            <h3 className="bold">Locations</h3>
                            <p>Whether your location is near or far, high or low, hot or cold, Performance Associates can train there. During our many years of training on nearly every continent and in dozens of countries, we have learned to work with a multitude of cultures. Proper training and experience within a culture is critical to success.</p>
                        </div>
                    </div>
                    <div className="location-content-locations col-12 col-lg-6">
                        <p>Argentina - Australia - Bolivia - Brazil - Canada - Chile - Colombia - DR Congo - Ghana - Guatemala - Guinea - Indonesia - Ireland - Japan - Kazakhstan - Liberia - Mali - Mauritania - Mexico - Mongolia - Namibia - New Caledonia - Papua New Guinea - Peru - Portugal - Sierra Leone - South Africa - Spain - Suriname - Switzerland - Sweden - Tanzania - Trinidad/Tobago - Turkey - USA - Uganda - Venezuela - Zambia</p>
                    </div>
                </div>
                {/* <embed src="./WorldMap/pai_client_map.html" title="location"></embed> */}
                {/* <WorldMap /> */}
            </div>
        </VisibilitySensor>
    )
}

export default Location;

