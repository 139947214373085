import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from 'reactstrap';
import './Nav.css';
import navList from '../utils/nav/nav-list';
import { Link } from 'react-scroll'; // Added import for Link component from react-scroll package

function Navigation() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    function populateList() {
        return navList.map((i, index) => {
            return (
                <NavItem key={index.toString()}>
                    <Link onClick={toggle} className="d-flex align-items-center justify-content-center nav-link btn-60" to={i} spy={true} hashSpy={true} isDynamic={true} offset={-60}>{i}</Link>
                </NavItem>
            )
        });
    }

  return (
      <Navbar dark expand="lg" className="nav-v2 w-100 d-flex justify-content-between px-md-5 px-3 py-0 sticky-top">
        <div className="logo-group">
            <Link key={"logoHome"} className="link-home" to={"Home"} spy={true} hashSpy={true} isDynamic={true} offset={-60}>
                <img src={'images/webp/pai_logo_simple-01.webp'} className="nav-img" alt="Performance Associates International, Inc Logo" />
            </Link>
            <a href="https://www.facebook.com/perfnet/" target="_blank" rel="noopener noreferrer" className="ml-2 p-2"><i className="fab fa-facebook fa-2x"></i></a>
            <a href="https://www.linkedin.com/company/performance-associates-international-inc./" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin fa-2x"></i></a>
        </div>
        <NavbarToggler onClick={toggle} right="true" />
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar className="d-flex align-items-stretch justify-content-end button-group mt-3 mt-lg-0">
                {populateList()}
                <NavItem className={`mb-3 mb-lg-0`}>
                    <a href="https://www.linkedin.com/company/performance-associates-international-inc./jobs/" className="d-flex align-items-center justify-content-center nav-link btn-60 career-link" target="_blank" rel="noopener noreferrer">Careers</a>
                </NavItem>
            </Nav>
        </Collapse>
      </Navbar>
  );
}

export default Navigation;