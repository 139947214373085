import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import Card from "../../../common/card/Card";
import "./Officer.css";

function Officer() {
  const [officerVis, setOfficerVis] = useState(false);
  const officerCheckVisibility = (isVisible) => {
    !officerVis && setOfficerVis(isVisible);
  };
  return (
    <div className="officer-wrapper">
      <div className="officer-group drop-shadow">
        <VisibilitySensor
          onChange={officerCheckVisibility}
          partialVisibility={"top"}
          minTopValue={10}
        >
          <div className="officer-card-group row d-flex">
            <div
              className={`officer-card-item ${
                !officerVis && "hidden-officer-top"
              } col-12 col-lg-6`}
            >
              <Card
                image="images/webp/anthony.webp"
                name="Anthony Montoya"
                title="President and Chief Executive Officer (CEO)"
              >
                <p>
                  Anthony Montoya has a bachelor of science in chemical
                  engineering from the University of Arizona and a master of
                  engineering in engineering management from the University of
                  Colorado Boulder. He also holds certification as a project
                  management professional (PMP). He has extensive experience in
                  mining and processing training. As a project manager for
                  Performance Associates International (PAI), Anthony developed
                  training programs for Swakop Uranium’s Husab uranium project
                  in Namibia; Torex Gold Resources’ El Limon-Guajes project in
                  Mexico; Midway Gold’s Pan Project in Nevada, USA; Chinalco’s
                  Toromocho plant in Peru; Intrepid Potash’s Carlsbad potash
                  operations in New Mexico, USA; and Barrick Gold Corporation’s
                  Goldstrike operations in Nevada, USA.
                </p>
                <p>
                  Anthony took over as president and CEO of PAI in 2022. He
                  oversees all PAI operations and resources and provides
                  executive oversight of project activities. Anthony is an
                  expert in projects both large and small, including industrial
                  construction projects, training development projects, and
                  manufacturing quality assurance projects. He is dedicated to
                  delivering quality training and support to PAI’s clients to
                  help them maximize their return on investment and empower
                  their workforce with knowledge and confidence.
                </p>
              </Card>
            </div>
            <div
              className={`officer-card-item ${
                !officerVis && "hidden-officer-bottom"
              } col-12 col-lg-6`}
            >
              <Card
                image="images/webp/jacqueline.webp"
                name="Jacqueline David"
                title="Chief Operations Officer"
              >
                <p>
                  Jacqueline David graduated from the Colorado School of Mines
                  with a degree in chemical and biochemical engineering. In her
                  work as a process engineer, she focused on the operation and
                  maintenance of CNC milling machines and black oxide coating
                  processes in a manufacturing plant; tested feed material
                  affects on smelting furnace performance at a platinum and
                  palladium smelter; and worked on process optimization and
                  commissioning of plant upgrades at a sugar manufacturer.
                </p>
                <p>
                  Since joining PAI, Jacqueline has developed training content
                  for the processing of copper, molybdenum, gold, silver, lead,
                  and zinc and has conducted operations and maintenance
                  training, on-site data collection, and project management in
                  Mexico, Canada, Finland, and Saudi Arabia.
                </p>
                <p>
                  Her interest lies in processes. She enjoys using her knowledge
                  and experience to figure out how different process plants
                  work, and then sharing that information with operators,
                  supervisors, and instrumentation and maintenance personnel.
                  Jacqueline firmly believes that a thorough understanding of
                  the process, including fundamentals, knowledge of equipment,
                  and upstream and downstream processes, leads to more efficient
                  and effective operations.
                </p>
              </Card>
            </div>
            <div
              className={`officer-card-item ${
                !officerVis && "hidden-officer-top"
              } col-12 col-lg-6`}
            >
              <Card
                image="images/webp/angela.webp"
                name="Angela Duchesne"
                title="Managing Consultant"
              >
                <p>
                  Angela Duchesne has a bachelor of applied science in
                  metallurgical engineering from the University of Toronto. She
                  has operations experience in mineral processing and
                  manufacturing. Angela has developed training material and
                  conducted on-site training for mining operations around the
                  world, including Canada, the United States, Mongolia,
                  Australia, Peru, Argentina, Mexico, Brazil, and Guatemala.
                </p>
                <p>
                  Angela’s background in mineral processing includes experience
                  with gold and copper processes. Her plant knowledge is
                  supported by laboratory experience and pilot plant operation.
                  Angela has experience as a plant metallurgist, operations
                  engineer, concentrator training superintendent, plant manager,
                  and senior applications specialist.
                </p>
                <p>
                  With Performance Associates, Angela has developed training
                  materials for gold, copper, and iron ore processes. She
                  authored the script and storyboards for an autoclave animation
                  and a heavy media separation animation.
                </p>
                <p>
                  In addition to her background in engineering, Angela has a
                  degree in education and is a certified teacher.
                </p>
                <p>
                  Angela sees a growing global interest in sustainability and
                  believes that good training is the first step in acquiring the
                  competence necessary for a sustainable operation.
                </p>
              </Card>
            </div>
            
            <div
              className={`officer-card-item ${
                !officerVis && "hidden-officer-bottom"
              } col-12 col-lg-6`}
            >
              <Card
                image="images/webp/sue.webp"
                name="Sue Ritz"
                title="Senior Consultant"
              >
                <p>
                  Sue Ritz graduated from the University of Utah with a bachelor
                  of science in metallurgical engineering. Since 2006, Sue has
                  worked as a design engineer for copper, gold, lead, silver,
                  and zinc process plants. She has 12 years of operational
                  experience in gold extraction from oxide and refractory ores,
                  as well as 10 years of laboratory experience and technical
                  support. Sue retired from the Army National Guard as a master
                  sergeant, with over 24 years of honorable service. Sue did one
                  tour of duty in Kuwait with the Nevada Army National Guard in
                  support of Operation Iraqi Freedom.
                </p>
                <p>
                  Sue’s technical skills include, process design and simulation,
                  metallurgical test work interpretation, evaluation and
                  development, and process plant auditing. She has process
                  expertise in autoclave operations, flotation, tank and heap
                  leaching, and comminution. Sue’s leadership skills include
                  external consultations, small study projects, and project
                  management.{" "}
                </p>
                <p>
                  Sue has a professional engineering license in Arizona and is a
                  registered member of the Society for Mining, Metallurgy, and
                  Exploration (SME).
                </p>
              </Card>
            </div>
            <div
              className={`officer-card-item ${
                !officerVis && "hidden-officer-bottom"
              } col-12 col-lg-6`}
            >
              <Card
                image="images/webp/andrew.webp"
                name="Andrew Falwell"
                title="Senior Consultant"
              >
                <p>
                  Andrew Falwell has a bachelor of science in chemical
                  engineering from the University of Arizona. He has experience
                  in managing operations and working with a wide range of
                  processes, including autoclaves, solvent extraction,
                  concentrators, flotation, crystallizers, heap leach, tank
                  leach, calciners, roasters, acid plants, and laboratories.
                </p>
                <p>
                  As a senior metallurgist at Freeport-McMoRan, Andrew managed a
                  multimillion dollar greenfield calciner project, which
                  increased plant throughput by 50 percent, improved
                  reliability, and enhanced quality. He also led multifunctional
                  teams across Freeport’s operations in the Americas and Europe
                  to identify process opportunities and turn proposals into
                  reality.
                </p>
                <p>
                  In addition, as a shift metallurgist lead, Andrew oversaw a
                  team of engineers who supported frontline concentrator
                  operations by interfacing with artificial intelligence process
                  improvement software. As a shift supervisor, he also led
                  operations crews at the pressure oxidation plant and solution
                  extraction plant to ensure safe operation, while meeting
                  production goals.
                </p>
                <p>
                  Throughout his career, Andrew has collaborated with
                  maintenance, operations, and management to troubleshoot
                  complex systems, drawing upon his technical expertise and
                  leadership skills to drive process improvements.
                </p>
              </Card>
            </div>
            <div
              className={`officer-card-item ${
                !officerVis && "hidden-officer-bottom"
              } col-12 col-lg-6`}
            >
              <Card
                image="images/webp/trevor.webp"
                name="Trevor Aronson"
                title="Senior Consultant"
              >
                <p>
                  Trevor Aronson has over 20 years of experience in the mining
                  industry. Trevor began his career in mining in 2004 following
                  four years of service in the United States Navy as an interior
                  communications electrician.
                </p>
                <p>
                  Trevor has worked as an operator and project manager for mines
                  and processing facilities in Arizona, Nevada, and Iowa. He has
                  also had various supervisory roles in mine operations, plant
                  operations, and engineering, maintenance, and electrical
                  departments.
                </p>
                <p>
                  Trevor’s technical experience includes pressure oxidation, ion
                  exchange, water reclamation, concentrators, crystallization,
                  filtration, leaching, solvent extraction, roasting, lean
                  manufacturing, and traditional and agile project management.
                  His most recent experience has involved leading
                  cross-functional teams to improve plant safety, equipment
                  reliability, and production.
                </p>
              </Card>
            </div>
          </div>
        </VisibilitySensor>
      </div>
    </div>
  );
}

export default Officer;
